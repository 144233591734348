import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import ListPage from './pages/list-page.js';
import SinglePage from './pages/details-page.js';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HumanVerification from './HumanVerification';
import UpdateScreen from './pages/components/update/updateScreen';
import OptionsScreen from './pages/components/options/optionsScreen';
// Google Tag Manager
const addGoogleTagManager = () => {
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.async = true;
	script.innerHTML = `
	  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-P3NTDB2P');
	`;
	document.head.appendChild(script);

	const noscript = document.createElement('noscript');
	noscript.innerHTML = `
	  <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P3NTDB2P"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
	`;
	document.body.insertBefore(noscript, document.body.firstChild);
  };

  // Initialize Google Tag Manager
addGoogleTagManager();

const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
	},
	{
		path: '/listings',
		element: <ListPage />,
	},
	{
		path: '/details/:id',
		element: <SinglePage />,
	},
	{
		path: '/human-verification', // Add this route
		element: <HumanVerification />,
	},
	{
		path: '/update', // Add this route for the update screen
		element: <UpdateScreen />,
	  },
	{
		path: '/options', // Add this route for the update screen
		element: <OptionsScreen />,
	  },
]);
ReactDOM.createRoot(document.getElementById('root')).render(
	<RouterProvider router={router} />
);
