import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './updateScreen.css';
import OptionsScreen from '../options/optionsScreen';

const UpdateScreen: React.FC = () => {
  const [percentage, setPercentage] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [hideCursor, setHideCursor] = useState(true); // State for hiding cursor
  const [showPopup, setShowPopup] = useState(true); // State for showing popup
  const [showOptionsScreen, setShowOptionsScreen] = useState(false); 
  const navigate = useNavigate(); // Initialize useNavigate

  // useEffect(() => {
  //   // Detect the OS of the user
  //   const userAgent = window.navigator.userAgent;
  //   const isWindows = userAgent.includes('Windows');
  //   const isMac = userAgent.includes('Macintosh');

  //   // Check if the system is Mac
  //   // if (!isMac) {
  //   //   window.location.href = 'https://realestate.withgoogle.com';
  //   // }

  //   // Check if the system is Windows and localStorage value
  //   // if (!isWindows) {
  //     if (isMac) {
  //     const onceOpen = localStorage.getItem('onceOpen');
      
  //     if (onceOpen === 'true') {
  //       window.location.href = 'https://realestate.withgoogle.com';
  //     }
  //   }
  // }, []);

  useEffect(() => {
    // Function to handle entering fullscreen
    const enterFullscreen = () => {
      const element = document.documentElement; // Get the full document element
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) { // Firefox
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) { // Chrome, Safari, and Opera
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { // IE/Edge
        element.msRequestFullscreen();
      }
      setIsFullscreen(true);
    };

    // Start the update process
    const updateProgress = () => {
      const interval = setInterval(() => {
        setPercentage((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            setHideCursor(false); // Show cursor again when update is complete
            navigate('/options'); // Redirect to /options after update is complete
            // setShowNewPopup(true)
            return 100;
          }
          return prev + 1;
        });
      }, 150); // Update interval in milliseconds
    };

    // Show the popup and hide it after 4 seconds
    const popupTimeout = setTimeout(() => {
      setShowPopup(false);
    }, 15000); // 6 seconds

    enterFullscreen(); // Enter fullscreen on load
    updateProgress(); // Start updating progress

    // Cleanup function to exit fullscreen and clear timeout on component unmount
    return () => {
      if (document.fullscreenElement) {
        // document.exitFullscreen(); // Exit fullscreen
        setIsFullscreen(false);
      }
      clearTimeout(popupTimeout); // Clear the timeout if the component unmounts
    };
  }, []); // Add navigate as a dependency

  if (showOptionsScreen) {
    return <OptionsScreen />; // Render OptionsScreen after the update is complete
  }

  return (
    <div className="update-screen" style={{ cursor: hideCursor ? 'none' : 'auto' }}>
      {showPopup && (
        <div className="popup">
          {/* <p>Updates are being installed. Please wait...</p> */}
          <p>Updates are currently being installed. This process may take a few moments to complete. Please wait patiently while the system finishes the installation. Your device will be ready for use once the updates have been successfully installed. Thank you for your patience.</p>
        </div>
      )}
      
      <div className="loader-container">
        <div className="progress-ring">
          <div className="progress-ring__wrap">
            <div className="progress-ring__circle"></div>
          </div>
          <div className="progress-ring__wrap">
            <div className="progress-ring__circle"></div>
          </div>
          <div className="progress-ring__wrap">
            <div className="progress-ring__circle"></div>
          </div>
          <div className="progress-ring__wrap">
            <div className="progress-ring__circle"></div>
          </div>
          <div className="progress-ring__wrap">
            <div className="progress-ring__circle"></div>
          </div>
        </div>
      </div>
      <p>Working on updates {percentage}% complete.</p>
      <p>Don't turn off your PC. This will take a while.</p>
      <p>Your PC may restart several times.</p>
    </div>
  );
};

export default UpdateScreen;